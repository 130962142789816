.main1 {
  background-image: url("../Images/New/background.png");
  background-position: right 0% bottom 35%;
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
  font-weight: 500;
  line-height: 1.618;
  font-size: 18px;
  font-family: "Nexa" !important;
}
.main1 h1 {
  font-family: "Nexa light", Sans-serif !important;
  font-weight: 600;
}
.method h2 {
  font-family: "Nexa" !important;
  color: #4054b2;
  line-height: 1;
  font-size: 40px !important;
}
.method h3 {
  font-family: "Nexa" !important;
  color: #4054b2;
  line-height: 1;
  font-size: 30px !important;
}
.img-shadow {
  width: 1100px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}
.pos {
  margin-left: 100px;
}
@media only screen and (max-width: 912px) {
  .method {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .main1 {
    min-height: 63vh;

    font-weight: 300;

    font-size: 15px;
  }
  .iconMargin {
    margin-bottom: 10px !important;
  }
  .marr {
    margin-top: 0px !important;
  }
  .img-shadow {
    width: 700px;
  }
  .pos {
    margin-left: 45px;
  }
}
@media only screen and (max-width: 550px) {
  .main1 {
    background-size: cover;
  }

  .method h1,
  .method h6 {
    margin: 10px;
  }
  .img2 {
    height: 50px;
    width: 50px;
  }
  .img-shadow {
    width: 350px !important;
  }
  .pos {
    margin-left: 30px;
  }
}

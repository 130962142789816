.VisualMain {
  background-image: url("../Images/Visual\ Design/main.jpg");
  background-position: right 0% bottom 80%;
  background-size: cover;
  min-height: 90vh;
  min-width: 100%;
  font-family: "Verdana";
  font-weight: 500;
  line-height: 1.618;
  font-size: 18px;
}

h6 {
  font-family: "Nexa light", Sans-serif !important;
  letter-spacing: 0.6px;
}

.main3 {
  background-image: url("../Images/New/background.png");
  background-position: right 0% bottom 35%;
  background-size: cover;
  min-height: 90vh;
  min-width: 100%;
  font-weight: 500;
  line-height: 1.618;
  font-size: 17px;
  font-family: "Nexa" !important;
  /* scroll-behavior: smooth;
    background-position: cover;
    background-position: center; */
  /* https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=820&q=80 */
}
.main3 h1 {
  font-family: "Nexa light", Sans-serif !important;
  font-weight: 600;
}
.href {
  font-size: 28px;
  font-weight: 700;
  color: #233f8e;
  font-family: "Nexa light", Sans-serif;
}
.process {
  font-size: 35px;
  font-weight: 700;
  color: #233f8e;
  font-family: "Nexa light", Sans-serif;
  scroll-behavior: smooth;
}
.para {
  width: 17%;
  font-size: 14px;
  font-family: "Nexa" !important;
  color: rgb(86, 84, 84);
}
.para p {
  font-weight: 500;
  color: #000;
}
@media only screen and (max-width: 550px) {
  .main3 {
    font-weight: 300;
    background-position: center;
    background-size: cover;
    font-size: 14px;
  }
  .main3 h3 {
    font-size: 18px;
  }
  .para {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 200px;
    margin-bottom: 40px;
  }
  .iimg {
    margin-top: 50px;
  }
}

@media screen and (max-width: 911px) and (min-width: 551px) {
  .main3 {
    background-size: cover;
    min-height: 75vh;
  }
  .para {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 200px;
    margin-bottom: 40px;
  }
  .iimg {
    margin-top: 50px;
  }
  .adjust {
    margin-left: 10%;
  }
  .adjust1 {
    margin-right: 10%;
  }
}
@media only screen and (width: 912px) {
  .main3 {
    background-position: center;
    background-size: cover;
    min-height: 69vh;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 913px) {
  .para {
    margin-right: 0px !important;
  }
}

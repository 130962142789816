* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
.main-sidebar {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  min-height: 97vh;

  position: sticky;
}
@media only screen and (max-width: 1200px) {
  .sidebar {
    background: rgb(0, 7, 61);

    color: white;
    min-height: 165vh !important;
    width: 0px;
    padding: 0px;

    position: sticky;
  }
}

.main-container1 {
  height: 85vh;
  width: 28vw;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.maiin {
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}
.makeCenter {
  margin-left: 520px;
  margin-top: 100px;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}

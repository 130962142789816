@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea {
  @apply p-0.5 border-2  ring-emerald-500 focus:ring  outline-none rounded-md;
}
input,
textarea {
  border-radius: 3px;
}

.primary-button {
  @apply rounded shadow-md py-2 px-4  hover:bg-teal-600 active:bg-teal-600;
}

label {
  @apply mb-10;
}

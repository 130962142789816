nav {
  background-color: #233f8e;
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67) 19ms;
}
@media only screen and (max-width: 600px) {
  .small {
    height: 250px;
    margin-top: 0px !important;
  }
  .mobile {
    margin-right: 20px;
  }
  .mob-footer-logo {
    height: 100px;
    width: 250px;
  }
}
.App-navbar > .activeLink {
  border-bottom: 3px solid rgb(34, 244, 234);

  padding-bottom: 13px;
  border-radius: 0px 0px 3.2px 3.2px;
}

.App-navbar > a:hover {
  border-bottom: 3px solid rgba(179, 196, 195, 0.999);

  padding-bottom: 13px;
  border-radius: 0px 0px 3.2px 3.2px;
  transition: cubic-bezier(0.17, 0.67, 0.83, 0.67) 19ms;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00baf0;
  background: #233f8e;

  color: #fff;
  height: 80px;
  padding: 0.5em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: #233f8e;
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 1280px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 65px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #fff;
    height: 4em;
    padding: 1em;
    transition: height 400ms cubic-bezier(0.11, 2, 0.32, 0.15);
  }
  .menu > li {
    display: flex;
    font-family: "Nexa" !important;
    font-weight: 400;
    text-align: left !important;
    font-size: 13px;

    padding: 0.5em 0;
    width: 100%;
    color: #000;
    background-color: #ffffff;
  }
}

.main4 {
  background-image: url("../Images/New/background.png");
  background-position: right 0% bottom 35%;
  background-size: cover;
  min-height: 90vh;
  min-width: 100%;
  font-weight: 500;
  line-height: 1.618;
  font-size: 17px;
  font-family: "Nexa" !important;
  /* scroll-behavior: smooth;
      background-position: cover;
      background-position: center; */
  /* https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=820&q=80 */
}
.main5 {
  background-image: url("../Images/New/background.png");
  background-position: right 0% bottom 65%;
  background-size: cover;
  min-height: 86.5vh;
  min-width: 100%;
  font-weight: 500;
  line-height: 1.618;
  font-size: 17px;
  font-family: "Nexa" !important;
  /* scroll-behavior: smooth;
      background-position: cover;
      background-position: center; */
  /* https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=820&q=80 */
}
.main4,
.main5 h1 {
  font-family: "Nexa light", Sans-serif !important;
  font-weight: 600;
}
.main4 h3 {
  font-family: "Nexa light", Sans-serif !important;
  font-weight: 600;
}
label {
  font-family: "Nexa light", Sans-serif !important;
  font-weight: 400;
}
.w {
  min-width: 515px;
}

@media only screen and (min-width: 1280px) {
  .marginTouch {
    margin-left: 10.9rem !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .w {
    min-width: 300px !important;
  }
  .pro1 {
    margin-top: 100px;
  }
}
@media screen and (max-width: 911px) and (min-width: 551px) {
  .main4 {
    background-size: cover;
    min-height: 75vh;
  }
  .other {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .other h1,
  h3 {
    margin: 30px;
    margin-bottom: -5px;
    text-align: center;
  }
  .w {
    min-width: 280px;
  }
}

@media only screen and (width: 912px) {
  .main4 {
    background-position: center;
    background-size: cover;
  }
  .pro {
    margin-top: 200px;
  }
  .pro1 {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 550px) {
  .main4 {
    font-weight: 300;
    background-position: center;
    background-size: cover;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .other {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .other h1,
  h3 {
    margin: 30px;
    margin-bottom: -5px;
    text-align: center;
  }
  .w {
    min-width: 280px;
  }
}

.main {
  background-image: url("../Images/New/background.png");
  background-position: right 0% bottom 35%;
  background-size: cover;
  min-height: 90vh;
  min-width: 100%;
  font-family: "Verdana";
  font-weight: 500;
  line-height: 1.618;
  font-size: 18px;
}

input,
textarea {
  font-size: medium !important;
}

.myborder h4:after {
  width: 50px;
  border-bottom: 2px solid orange;
  content: "";
  bottom: -6px;
  left: 0;
  position: absolute;
}

.w1 {
  min-width: 355px;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .w1 {
    min-width: 300px !important;
  }
}
@media screen and (max-width: 911px) and (min-width: 551px) {
  .w1 {
    min-width: 280px;
  }
}

@media only screen and (max-width: 550px) {
  .w1 {
    min-width: 280px;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    min-height: 80vh;
    font-family: "Verdana";
    font-weight: 300;

    font-size: 10px;
  }
  .vs {
    margin: 40px;
  }
  .small {
    margin-bottom: 1px !important;
  }
}

@media (max-width: 767px) {
  .main-container1 {
    height: auto !important;
    width: auto !important;

    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .mob {
    width: 50vw;
  }
}
